<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron adjuntos"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="fichero.idfichero"
      @click-item="clickListItem"
    >
      <template
        #listItem="slotProps"
      >
        <fichero-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :readonly="readonly"
          :has-perm-borrar="hasDeletePerm($route.meta.permission.idobjeto)"
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
    <b10-fab-button
      v-if="!readonly && hasInsertPerm($route.meta.permission.idobjeto)"
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './FicheroListData'
import FicheroListItem from './components/FicheroListItem'
import { getTablaById } from '@/utils/consts'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesOfflineFicheroList'

export default {
  components: {
    FicheroListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    addRoute: {
      type: String,
      default: 'offline__fichero-add'
    },
    viewRoute: {
      type: String,
      default: 'offline__fichero-view'
    }
  },
  data () {
    return {
      pageStoreName,
      tablaPrincipal: {},
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    readonly: get(`${pageStoreName}/readonly`),
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.tablaPrincipal = getTablaById(parseInt(this.routeParams.idtabla))
      this.title = `Adjuntos de ${this.tablaPrincipal.titleSingular}`
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectFichero(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idtabla,
          this.routeParams.id,
          this.routeQuery.extra,
          !!this.usuarioIdtecnico,
          !!this.usuarioIdcomercial,
          !!this.usuarioIdvigilante
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectFicheroRows(this, dirty.modified), dirty, 'fichero.idfichero'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: this.viewRoute,
        params: {
          idfichero: item.data.fichero.idfichero,
          readonly: this.readonly,
        },
      })
    },
    async clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: this.addRoute,
        params: {
          idtabla: this.routeParams.idtabla,
          id: this.routeParams.id,
        }
      })
    },
    async clickDelete (data) {
      const res = await this.$alert.showConfirm(`¿Deseas eliminar el adjunto "${data.item.fichero.nombre_fichero}"?`)
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await this.$offline.fichero.deleteSync(data.item.fichero.idfichero)
          await this.dispatchStore('deleteItem', data.index)
        } finally {
          this.$loading.hide()
        }
      }
    },
  }
}
</script>
