<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar|uppercase }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
    </v-list-item-content>
    <v-list-item-action
      v-if="!readonly && hasPermBorrar"
    >
      <v-btn
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-action>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { stringToHslColor } from '@/utils/ui'
import { nonEmpty } from '@/utils/templates'
import { extractFileExtension } from '@/utils/files'
import { getTablaById } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    hasPermBorrar:{
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      const entidad = getTablaById(item.fichero.idtabla).titleSingular
      item.title = item.fichero.nombre_fichero
      item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
        Fichero de ${entidad} (${item.tfichero.descripcion})
        ${item.fichero.observaciones}`)
      const extension = extractFileExtension(item.fichero.nombre_fichero)
      item.avatar = extension
      item.avatarColor = stringToHslColor(item.avatar)
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    }
  },
}
</script>
