import { likeRegExp } from '@/offline/database'

export default {
  _selectFicheroBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.fichero)
      .innerJoin(
        tables.tfichero,
        tables.fichero.idtfichero.eq(tables.tfichero.idtfichero)
      )
      .orderBy(tables.fichero.nombre_fichero)
  },
  /*eslint no-unused-vars: ["error", { "args": "none" }]*/
  async selectFichero (
    Vue, filter, search, sorter, page, idtabla, id, extra = [], esTecnico, esComercial, esVigilante
  ) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.fichero.nombre_fichero.match(reQ),
          tables.tfichero.descripcion.match(reQ),
        )
      )
    }
    let whereExtra = []
    if (extra.length > 0) {
      for (const extraFilter of extra) {
        whereExtra.push(
          Vue.$offline.db.op.and(
            tables.fichero.idtabla.eq(extraFilter.idtabla),
            tables.fichero.id.eq(extraFilter.id),
          )
        )
      }
    }
    whereExtra.push(
      Vue.$offline.db.op.and(
        tables.fichero.idtabla.eq(idtabla),
        tables.fichero.id.eq(id),
      )
    )
    whereFilter.push(
      Vue.$offline.db.op.or(
        tables.tfichero.visible_movilidad_sat.eq(esTecnico),
        tables.tfichero.visible_movilidad_comercial.eq(esComercial),
        tables.tfichero.visible_movilidad_vigilancia.eq(esVigilante),
      )
    )
    where.push(
      Vue.$offline.db.op.and(
        ...whereSearch,
        ...whereFilter,
        Vue.$offline.db.op.or(
          ...whereExtra
        )
      )
    )
    return [
      await this._selectFicheroBase(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .exec(),
      []
    ]
  },
  async selectFicheroRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return await this._selectFicheroBase(Vue)
      .where(tables.fichero.idfichero.in(pks))
      .exec()
  },
}
